<template>
  <div>
    <PageTitlebar title="Advertiser Account Billing History">
      <template v-slot:top>
        <Link
          :to="{
            name: 'AdvertiserBillingOverview'
          }"
          label="Back to Billing"
          icon="sym_r_chevron_left"
        />
      </template>

      <template v-slot:actions>
        <DateRangeSelector
          :initial-start-date="startDate"
          :initial-end-date="endDate"
          @update="
            dates => {
              updateDateRange(dates);
              populateBillingHistory();
            }
          "
        />
      </template>

      <template v-slot:bottom>
        <div v-if="!loading">
          <p>{{ advertiserAccount.name }}</p>
          <div class="flex items-center">
            <Label :text="advertiserAccountId" />
          </div>
        </div>
      </template>
    </PageTitlebar>

    <DataTable
      table-key="advertiserAdminBillingOverview"
      row-key="id"
      title=""
      :columns="columns"
      :rows="payments"
      :pagination="{
        sortBy: 'time_created',
        descending: true,
        page: 1,
        rowsPerPage: 10
      }"
      :loading="loading"
      :downloadable="false"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <template v-slot:status="row">
        <div>
          <StatusBadge
            :status="
              {
                open: 'paused',
                failed: 'error',
                paid: 'success'
              }[row.status]
            "
            :custom-label="row.status == 'failed' ? 'error' : row.status"
          />

          <q-tooltip
            v-if="['paid', 'failed'].includes(row.status)"
            anchor="center left"
            self="center right"
          >
            <span v-if="row.status == 'paid'">
              Invoice Paid -
              {{ convertUtcToBusinessTimezone(row.time_created, "lll") }}
            </span>
            <span v-else-if="row.status == 'failed'">
              An internal error occurred. A member of our team is reviewing the
              issue and will contact you shortly.
            </span>
          </q-tooltip>
        </div>
      </template>

      <template v-slot:payment_method="row">
        <div
          v-if="row.stripe_payment_method_id.length > 0"
          class="flex items-center q-gutter-x-sm"
        >
          <PaymentMethodIcon :brand="row.payment_method_brand" />
          <div>
            <p>
              {{ capitalizeText(row.payment_method_brand) }}
              &bull;&bull;&bull;&bull; {{ row.payment_method_last_four }}
            </p>
          </div>
        </div>
        <span v-else>
          -
        </span>
      </template>

      <template v-slot:actions="row">
        <ActionsDropdownButton
          :actions="[
            {
              action: () => openStripeHostedInvoice(row.stripe_invoice_url),
              label: 'View Invoice',
              icon: 'sym_r_receipt_long'
            }
          ]"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import DataTable from "@/components/UI/DataTable.vue";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton.vue";
import PaymentMethodIcon from "@/components/UI/PaymentMethodIcon.vue";
import StatusBadge from "@/components/UI/StatusBadge.vue";
import DateRangeSelector from "@/components/UI/DateRangeSelector.vue";
import Label from "@/components/UI/Label.vue";
import Link from "@/components/UI/Link.vue";
import axios from "axios";

export default {
  name: "AdvertiserAccountBillingHistory",
  props: {
    advertiserAccountId: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    Link,
    Label,
    DateRangeSelector,
    StatusBadge,
    PaymentMethodIcon,
    ActionsDropdownButton,
    DataTable,
    PageTitlebar
  },
  data() {
    return {
      loading: true,
      submitting: false,
      startDate: this.$dayjs()
        .tz()
        .format("YYYY-01-01 HH:mm:ss"),
      endDate: this.$dayjs()
        .tz()
        .format("YYYY-MM-DD 23:59:59"),
      advertiserAccount: [],
      payments: [],
      columns: [
        {
          name: "stripe_invoice_number",
          label: "Invoice Number",
          field: "stripe_invoice_number",
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "time_created",
          label: "Date",
          field: row =>
            row.time_created
              ? this.convertUtcToBusinessTimezone(row.time_created, "ll")
              : "-",
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date",
          align: "left"
        },
        {
          name: "amount",
          label: "Amount",
          field: "amount",
          format: value =>
            !value || value == "-"
              ? "-"
              : this.numberFormatter(value, {
                  style: "currency",
                  currency: "USD"
                }),
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number",
          align: "left"
        },
        {
          name: "status",
          label: "Status",
          field: row => this.capitalizeText(row.status),
          type: "dimension",
          hideField: true
        },
        {
          name: "payment_method",
          label: "Payment Method",
          field: row =>
            row.stripe_payment_method_id.length > 0
              ? this.capitalizeText(row.payment_method_brand) +
                " Ending in " +
                row.payment_method_last_four
              : "-",
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension",
          hideField: true
        },
        {
          name: "actions",
          label: "",
          field: "",
          filterable: false,
          sortable: false,
          align: "right"
        }
      ]
    };
  },
  computed: {},
  mounted() {
    this.$store
      .dispatch("advertiserAccounts/GET_REQUEST", {
        params: {
          id: this.advertiserAccountId
        }
      })
      .then(data => {
        if (data.length > 0) {
          this.advertiserAccount = data[0];
          this.populateBillingHistory();
        }
      });
  },
  methods: {
    populateBillingHistory() {
      this.loading = true;

      axios({
        url:
          process.env.VUE_APP_API_BASE_URL +
          "/network/app/billing/advertiser-account-invoice-history",
        params: {
          id: this.advertiserAccountId,
          start_date: this.startDate,
          end_date: this.endDate
        }
      })
        .then(resp => {
          this.payments = resp.data.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    openStripeHostedInvoice(invoiceUrl) {
      window.open(invoiceUrl, "_blank");
    },
    updateDateRange(dates = null) {
      if (dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
